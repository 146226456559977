import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PAGE_LIMIT } from 'constants/app.constant'
import BaseService from 'services/BaseService'
import { removeDuplicatedObject } from 'utils/removeDuplicatedObject'

export const fetchCatalogueDetails = createAsyncThunk(
    'catalogueDetails/fetch',
    async (query) => {
        const page = query?.page || 1
        const limit = query?.limit || PAGE_LIMIT
        const facetQuery = query?.facetQuery || []
        const id = query.id || ''
        const searchQuery = query.searchQuery || ''
        try {
            const response = await BaseService.post(
                `/campaign/catalog/search/${id}?page=${page}&limit=${limit}`,
                { searchQuery, facetQuery }
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch catalogues data')
        }
    }
)

const catalogueDetailsSlice = createSlice({
    name: 'catalogueDetails',
    initialState: {
        campaign: {},
        data: [],
        isLoading: false,
        error: null,
        allItems: [],
        filters: [],
    },
    reducers: {
        updateFilters: (state, action) => {
            const { key, value } = action.payload
            const existingFilterIndex = state.filters.findIndex(
                (filter) => filter.key === key
            )
            if (existingFilterIndex !== -1) {
                state.filters[existingFilterIndex].value = value
            } else {
                state.filters.push({ key, value })
            }
        },
        resetFilters: (state) => {
            state.filters = []
        },
        removeFilterByKey: (state, action) => {
            const keyToRemove = action.payload
            state.filters = state.filters.filter(
                (filter) => filter.key !== keyToRemove
            )
        },
        resetCatalogDetails: (state) => {
            state.campaign = {}
            state.data = []
            state.isLoading = false
            state.error = null
            state.allItems = []
            state.filters = []
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCatalogueDetails.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCatalogueDetails.fulfilled, (state, action) => {
                const items = action.payload?.result?.items || []
                const campaign = action.payload?.result?.campaign || {}
                state.isLoading = false
                state.data = action.payload
                state.campaign = campaign
                state.allItems = removeDuplicatedObject([
                    ...state.allItems,
                    ...items,
                ])
            })
            .addCase(fetchCatalogueDetails.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.data = []
                state.allItems = []
                state.filters = []
            })
    },
})
export const {
    updateFilters,
    removeFilterByKey,
    resetFilters,
    resetCatalogDetails,
} = catalogueDetailsSlice.actions

export const selectData = (state) => state.catalogueDetails.data
export const selectCampaignData = (state) => state.catalogueDetails.campaign
export const selectAllItems = (state) => state.catalogueDetails.allItems
export const selectIsLoading = (state) => state.catalogueDetails.isLoading
export const selectError = (state) => state.catalogueDetails.error
export const selectFilter = (state) => state.catalogueDetails.filters

export default catalogueDetailsSlice.reducer
