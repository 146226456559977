import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import Button from '../Buttons/index'

function ErrorFallback({ error, resetErrorBoundary }) {
    const isServerError = error?.message?.includes('500')

    // Additional error details for display
    const errorMessage = error?.message || 'Une erreur est survenue'
    const errorStack = error?.stack || ''

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md max-w-md text-center min-w-full lg:min-w-[40vw] lg:max-w-[40vw]">
                <h2 className="text-2xl font-bold text-red-600 mb-4">
                    Oops! Une erreur est survenue
                </h2>
                <p className="text-gray-700 mb-4">
                    {isServerError
                        ? "Une erreur de serveur s'est produite (500). Veuillez réessayer plus tard."
                        : "Une erreur imprévue s'est produite. Veuillez réessayer."}
                </p>

                {process.env.NODE_ENV === 'development' && (
                    <div className="text-left mb-4">
                        <h2 className="text-lg font-semibold mb-2">
                            Détails de l'erreur :
                        </h2>
                        <div className="bg-gray-200 p-4 rounded">
                            <p className="text-sm text-gray-600">
                                {errorMessage}
                            </p>

                            <pre className="text-xs text-red-700 whitespace-pre-wrap">
                                {errorStack}
                            </pre>
                        </div>
                    </div>
                )}
                <div className="flex flex-col gap-2">
                    <Button onClick={resetErrorBoundary} variant="solid">
                        Essayer à nouveau
                    </Button>
                    <Button
                        onClick={() => {
                            window.location.href = '/home'
                        }}
                    >
                        Retour vers la page principale
                    </Button>
                </div>
            </div>
        </div>
    )
}

function GlobalErrorBoundary({ children }) {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                window.location.reload()
            }}
        >
            {children}
        </ErrorBoundary>
    )
}

export default GlobalErrorBoundary
