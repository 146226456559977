import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { PAGE_LIMIT } from 'constants/app.constant'
import BaseService from 'services/BaseService'

// Async thunk action to fetch data from the API
export const fetchCatalogues = createAsyncThunk(
    'catalogues/fetch',
    async (query) => {
        const page = query?.page || 1
        const limit = PAGE_LIMIT
        try {
            const response = await BaseService.get(
                `/campaign/catalog/getCampaignsCatalogsByCustomer/?page=${page}&limit=${limit}`
            )
            return response.data
        } catch (error) {
            throw new Error('Failed to fetch catalogues data')
        }
    }
)

// Redux Toolkit slice
const cataloguesSlice = createSlice({
    name: 'catalogues',
    initialState: {
        data: [],
        isLoading: false,
        error: null,
    },
    reducers: {
        resetCatalogsData: (state) => {
            state.error = null
            state.data = []
            state.isLoading = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCatalogues.pending, (state) => {
                state.isLoading = true
                state.error = null
            })
            .addCase(fetchCatalogues.fulfilled, (state, action) => {
                state.isLoading = false
                state.data = action.payload
            })
            .addCase(fetchCatalogues.rejected, (state, action) => {
                state.isLoading = false
                state.error = action.error.message
                state.data = []
            })
    },
})
export const { resetCatalogsData } = cataloguesSlice.actions

export const selectData = (state) => state.catalogues.data
export const selectIsLoading = (state) => state.catalogues.isLoading
export const selectError = (state) => state.catalogues.error

export default cataloguesSlice.reducer
